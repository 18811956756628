import { Controller } from "@hotwired/stimulus"
import $ from "jquery"

export default class extends Controller {
  connect() {
    $(this.element).on("change", this.updateForm.bind(this))
  }

  updateForm(event) {
    const cageId = event.target.value
    const parentContainer = $(event.target).closest(".export-line-item")
    const errorMessageElement = parentContainer.find(".cage-not-found")

    if (!cageId) return

    this.fetchCageData(cageId)
      .then((response) => {
        if (!response.ok) {
          this.showError(errorMessageElement, "Cage not found")
          return response.json().then((data) => {
            this.showError(errorMessageElement, data.error)
          })
        }
        this.hideError(errorMessageElement)
        return response.json()
      })
      .then((responseData) => {
        if (responseData && responseData.data) {
          this.updateFields(parentContainer, responseData.data)
        }
      })
  }

  fetchCageData(cageId) {
    return fetch(`/cages/${cageId}/current_card_data`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
    })
  }

  updateFields(parentContainer, data) {
    const numberOfAnimalsInput = parentContainer.find("input[name*='number_of_animals']")
    const genderSelect = parentContainer.find("select[name*='gender']")

    if (numberOfAnimalsInput.length) {
      numberOfAnimalsInput.val(data.number_of_animals || "")
    }

    if (genderSelect.length) {
      genderSelect.val(data.sex === "female" ? "female" : "male")
      genderSelect.selectpicker("refresh")
    }
  }

  showError(element, message) {
    element.text(message).show()
  }

  hideError(element) {
    element.hide()
  }
}
